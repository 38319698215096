import { EQimaFontFamily } from '../models/enums/qima-font-family.enum';
import { InjectionToken } from '@angular/core';
/**
 * @description
 * Holds the default font-family
 * This will be used by the {@link QimaFontFamilyService}
 * In case you configured the translation, this would easily be overridden on initialisation
 * @type {InjectionToken<EQimaFontFamily>}
 */
export const QIMA_DEFAULT_FONT_FAMILY_TOKEN = new InjectionToken('The default font-family', {
  factory() {
    return EQimaFontFamily.INTER;
  }
});
