import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'qpFindLanguageFromKey',
  standalone: true,
})
export class QpFindLanguageFromKeyPipe implements PipeTransform {
  private readonly languages: { [key in string]: { name: string } } = {
    'zh': { name: '中文（简体）' },
    'en': { name: 'English' },
    'en-produce': { name: 'English (Pr)' },
    'fr': { name: 'Français' },
    'de': { name: 'Deutsch' },
    'ja': { name: '日本' },
    'ko': { name: '한국인' },
    'es': { name: 'Español' },
    'es-produce': { name: 'Español (Pr)' },
    'bn': { name: 'বাংলা' },
    'hi': { name: 'हिन्दी' },
    'ta': { name: 'தமிழ்' },
    'vi': { name: 'Tiếng Việt' },
    'pt': { name: 'Português' },
    'en-action': { name: 'Act' },
  };

  public transform(lang: string): string {
    return this.languages[lang].name;
  }
}
