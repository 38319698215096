import { QpArea } from '@library/models/qp-administrative-area.models';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * @description
 * This is voluntarily not a DI, meaning that the Injectable decorator should not be added
 * Some components needs this service only when used
 * You should in that case provide the DI with a custom class provider in order to create the service with
 * your own implementation based on the project
 */
export abstract class QpAddressService {
  public abstract getAdministrativeAreas$(countryCode: Readonly<string>): Observable<HttpResponse<QpArea[]>>;
}
