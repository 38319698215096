import { EQpInputPasswordAutocompleteValue } from '@library/components/qp-input-password/qp-input-password.models';
import { EQpInputSearchSelectAutocompleteValue } from '@library/components/qp-input-search-select/qp-input-search-select.models';
import { EQpInputTextAutocompleteValue } from '@library/components/qp-input-text/qp-input-text.models';

/**
 * @description Autocomplete possible values
 * @tutorial https://developers.google.com/web/updates/2015/06/checkout-faster-with-autofill
 */
export enum EQpAutocompleteValue {
  EMAIL = 'email',
  PASSWORD = 'password',
  SHIPPING_STREET_ADDRESS = 'shipping street-address',
  SHIPPING_LOCALITY = 'shipping locality',
  SHIPPING_REGION = 'shipping region',
  SHIPPING_POSTAL_CODE = 'shipping postal-code',
  SHIPPING_COUNTRY = 'shipping country',
  USERNAME = 'username',
  CURRENT_PASSWORD = 'current-password',
}

export type QpAutocompleteValueType =
  | EQpAutocompleteValue
  | EQpInputPasswordAutocompleteValue
  | EQpInputSearchSelectAutocompleteValue
  | EQpInputTextAutocompleteValue;
