import { InjectionToken } from '@angular/core';
import { of, BehaviorSubject } from 'rxjs';
class QimaDefaultTranslationService {
  constructor() {
    this.activeLanguageSubject$ = new BehaviorSubject('en');
  }
  translate$(translationKey) {
    return of(`Missing translation for ${translationKey}`);
  }
}
/**
 * @description
 * The token consumed by the {@link QimaTranslationService} to translate
 * @type {InjectionToken<QimaTranslationFactory>}
 */
export const QIMA_TRANSLATION_FACTORY_TOKEN = new InjectionToken('The default factory used by the translation service', {
  factory() {
    return new QimaDefaultTranslationService();
  }
});
